import Artpunk from './img/artpunk.jpg';
import Athenadexfi from './img/Athenadexfi.jpg';
import Barc from './img/Barclays-PLC-stock-price.jpg';
import BrainTumor from './img/brain-tumour.JPG';
import Dukaanni from './img/dukaanni.png';
import Pumpfun from './img/pumpfun.png';
import Membula from './img/membula.png';
import Ordibets from './img/ordibets.png';
import Passerine from './img/passerine.png';
import Pdf from './img/pdf-ask-answer.jpg';
import RecursiveRune from './img/Recursive-Rune.jpg';
import RewardStaking from './img/reward-staking.png';
import JossAI from './img/JossAI.JPG';
import Tennis from './img/tennis.gif';
import Translation from './img/translation.jpg';
import Vkingplays from './img/vkingplays.jpg';
import WizardTech from './img/wizard-tech.png';
import Jackpot from './img/jackpot.png';
import Bitcoin from './img/bitcoin.JPG';
import RoadDamage from './img/Road-damage.JPG';
import Similarity from './img/similarity.JPG';
import Vehicle from './img/vehicle.gif';
import TableTennis from './img/table.gif';
import Dating from './img/series-chatbot.jpeg';
import Bitmap from './img/bitmap.png';

const header = {
  homepage: 'https://www.devcutup.com/',
  title: 'Janus',
}

const about = {
  name: 'Janus Dev',
  role: 'FullStack | Blockchain',
  description:
    '',
  resume: '',
  social: {
    gmail: "mailto:devcutup@gmail.com", 
    github: 'https://github.com/cutupdev',
    // linkedin: 'https://linkedin.com',
    whatsapp: 'https://wa.me/13137423660',
    telegram: 'https://t.me/DevCutup',
    twitter: 'https://twitter.com/januscutup'
  },
}

const projects = [
  {
    name: 'Athena Dexfi',
    image: Athenadexfi,
    description:
      'Smart Trading Platform where big whales invest their money Launchpad platform',
    stack: ['Solidity', 'Web3', 'TypeScript'],
    sourceCode: 'https://github.com/cutupdev/Athena-Dexfi',
    livePreview: 'https://athenadexfi.io/',
  },
  {
    name: 'JossAI',
    image: JossAI,
    description:
      'Upload photos of people you want to meet and create an AI video then to make virtual AI calls',
    stack: ['STT', 'TTS', 'OpenAI', 'Runway', 'AWS S3', 'React', 'Node.js', 'MongoDB', 'Python', 'JavaScript'],
    sourceCode: 'https://github.com/cutupdev/JossAI-frontend',
    livePreview: 'https://www.jossai.com/',
  },
  {
    name: 'Pumpfun',
    image: Pumpfun,
    description:
      'Pump.fun fork and additional functionalities on Solana',
    stack: ['Solana', 'Rust', 'Anchor', 'liquidity', 'Node.js', 'React.js', 'MongoDB'],
    sourceCode: 'https://github.com/cutupdev/Pumpfun-SC',
    livePreview: 'https://app.ape.lol/',
  },
  {
    name: 'Clothes similarity app',
    image: Similarity,
    description:
      'Clothes similarity app, clients can order styles what they want',
    stack: ['Python', 'tensorflow', 'encode', 'similarity-search'],
    sourceCode: 'https://github.com/cutupdev/Clothes-Similarity-Comparison/',
    livePreview: 'https://ioannotator.com/image-similarity-search/',
  },
  {
    name: 'Bitmap community',
    image: Bitmap,
    description:
      'Ordinal inscription service',
    stack: ['Bitcoin', 'Ordinals', 'Typescript'],
    sourceCode: 'https://github.com/cutupdev/bitmap-community',
    livePreview: 'https://bitmap.community/',
  },
  {
    name: 'PDF Question-Answer System',
    image: Pdf,
    description:
      'PDF upload, analyze. When clients ask about the content, search suitable content and offer result',
    stack: ['Python', 'OpenAI', 'Embedding'],
    sourceCode: 'https://github.com/cutupdev/pdf-ask-system',
    livePreview: 'https://sharly.ai/',
  },
  {
    name: 'Casino Game Tool',
    image: Vkingplays,
    description:
      'Several Casino Game Tool by payment using credit card',
    stack: ['Javascript', 'React.js', 'Node.js', 'MongoDB'],
    sourceCode: 'https://github.com/cutupdev/Vkingplays',
    livePreview: 'https://uat.vkingplays.com/',
  },
  {
    name: 'AI dating app',
    image: Dating,
    description:
      'Clients can meet(generate) virtual partner then have a chatting',
    stack: ['Langchain', 'OpenAI', 'stableDifussion'],
    sourceCode: 'https://github.com/cutupdev/AI-dating',
    livePreview: 'https://www.harmonitechteam.com/',
  },
  {
    name: 'Bitcoin wallet toolkit',
    image: Membula,
    description:
      'This project offers various useful functionalities for bitcoin wallet',
    stack: ['Javascript', 'Bitcoin', 'Ordinals', 'Runes'],
    sourceCode: 'https://github.com/cutupdev/btc-wallet-support-toolkit',
    livePreview: 'https://www.membula.io/',
  },
  {
    name: 'Jackpot Game',
    image: Jackpot,
    description:
      'Jackpot Game on Solana',
    stack: ['Solana', 'P2E Game', 'Next.js', 'MongoDB'],
    sourceCode: 'https://github.com/cutupdev/Jackpot-Solana-Frontend',
    livePreview: 'https://wheel.mctoken.xyz/',
  },
  {
    name: 'Barclays PLC stock price prediction',
    image: Barc,
    description:
      'Barclays PLC stock price prediction by LSTM. Data collection using Python yfinance and flask for API.',
    stack: ['Tensorflow', 'LSTM', 'Flask', 'yfinance'],
    sourceCode: 'https://github.com/cutupdev/Barclays-PLC-stock-price-prediction',
    livePreview: ' https://20aa-45-8-22-59.ngrok-free.app',
  },
  {
    name: 'Recursive Rune',
    image: RecursiveRune,
    description:
      'Implemented Recursive Rune. This Rune Parent Inscription has 4 child inscriptions. Each Child Inscription contains 1k Harmonitech Runes',
    stack: ['Bitcoin', 'Recursive Ordinal', 'Taproot'],
    sourceCode: 'https://mempool.space/testnet/tx/f984a855960a203395facb062f173700143f3d19e48b6918bde5dc29f445a3fe',
    livePreview: 'https://with18testnet.ordstuff.info/inscription/ea4303aaa2c7939931a2ba129c9fc915d1905d441f2a74b6cd694c71665c7682i0',
  },
  {
    name: 'Bitcoin price prediction',
    image: Bitcoin,
    description:
      'Bitcoin price prediction project using LSTM',
    stack: ['Python', 'Keras', 'LSTM', 'Binance API'],
    sourceCode: 'https://github.com/cutupdev/Pumpfun-SC',
    livePreview: 'https://github.com/cutupdev/Bitcoin-Price-Prediction-Model-Deep-Research-in-LSTM-GRU/',
  },
  {
    name: 'Rewards Staking',
    image: RewardStaking,
    description:
      'Rewards Staking for Kingdom of Dwarves NFT collections on Solana Chain',
    stack: ['Rust', 'TypeScript'],
    sourceCode: 'https://github.com/cutupdev/Kod-Reward-Staking',
    livePreview: 'https://anybodies.io/c/kod',
  },
  {
    name: 'Wizard-Tech',
    image: WizardTech,
    description:
      'Offering customized emotional advice based on analysis of emotion based on words and face',
    stack: ['Python', 'Django', 'HuggingFace', 'BERT', 'CNN'],
    sourceCode: 'https://github.com/cutupdev/Wizard-Tech',
    livePreview: 'https://wizardtech.carrd.co/',
  },
  {
    name: 'Dukaanni',
    image: Dukaanni,
    description:
      'Ecommerce website',
    stack: ['React.js', 'Node.js', 'MongoDB', 'Stripe'],
    sourceCode: 'https://github.com/cutupdev/Dukaanni',
    livePreview: 'https://dukaanni.netlify.app',
  },
  {
    name: 'Vehicle counting project',
    image: Vehicle,
    description:
      'Real time vehicle counting',
    stack: ['Python', 'YOLO'],
    sourceCode: 'https://github.com/cutupdev/Vehicle-Real-Time-Counting-YOLO/',
    livePreview: 'https://www.harmonitechteam.com/',
  },
  {
    name: 'Ordibets',
    image: Ordibets,
    description:
      'Cryptocurrency deposit site',
    stack: ['Next.js', 'Web3', 'MongoDB', 'Deposit'],
    sourceCode: 'https://github.com/cutupdev/Ordibets',
    livePreview: 'https://ordibets.netlify.app/',
  },
  {
    name: 'English-Fula Translation',
    image: Translation,
    description:
      'English-Fula Translation System using Marian',
    stack: ['Python', 'LLM', 'Marian NMT'],
    sourceCode: 'https://github.com/cutupdev/fula-to-english-translation',
    livePreview: 'https://www.stars21.com/translator/english/fula/',
  },
  {
    name: 'Artpunk',
    image: Artpunk,
    description:
      'NFT staking / unstaking on Solana chain',
    stack: ['Smart Contract', 'Web3', 'Rust', 'Typescript'],
    sourceCode: 'https://github.com/cutupdev/artpunk-unstaking',
    livePreview: 'https://artpunks.fun/',
  },
  {
    name: 'Tennis Ball Tracking',
    image: Tennis,
    description:
      "Tennis ball tracking app for player's game analysis",
    stack: ['Python', 'YOLO', 'Flask'],
    sourceCode: 'https://github.com/cutupdev/Real-Time-Tennis-Ball-Tracking-in-Bad-Angle',
    livePreview: 'https://swing.tennis/',
  },
  {
    name: 'Passerine',
    image: Passerine,
    description:
      'Fitness support site',
    stack: ['Next.js', 'MongoDB', 'Fitness'],
    sourceCode: 'https://github.com/cutupdev/Passerine',
    livePreview: 'https://passerine.netlify.app/',
  },
  {
    name: 'Brain Tumor',
    image: BrainTumor,
    description:
      'Brain Tumor segmentaion, trained by BraTS2020 datasets',
    stack: ['Tensorflow', 'Encoder', 'Decoder'],
    sourceCode: 'https://github.com/cutupdev/Brain-Tumors-Segmentation-using-Encoder-Decoder',
    livePreview: 'https://www.harmonitechteam.com/',
  },
  {
    name: 'Road Damage Detector',
    image: RoadDamage,
    description:
      'Road Damage Detector app from photo',
    stack: ['Python', 'Image processing', 'YOLO'],
    sourceCode: 'https://github.com/cutupdev/Road-Damage-Detection/',
    livePreview: 'https://www.harmonitechteam.com/',
  },
  {
    name: 'Table tennis ball tracking',
    image: TableTennis,
    description:
      'Table tennis ball tracking by TTNet',
    stack: ['Python', 'Pytorch', 'TTNet', 'CNN'],
    sourceCode: 'https://github.com/cutupdev/Table-Tennis-Ball-Tracking-using-CNN/',
    livePreview: 'https://www.harmonitechteam.com/',
  },
]

const skills = [
  'AI',
  'Smart Contact',
  'Web3',
  'Python',
  'Rust',
  'Go',
  'Solidity',
  'Anchor',
  'Django',
  'Flask',
  'Streamlit',
  'MySQL',
  'MongoDB',
  'PostgreSQL',
  'firebase',
  'Pinecone',
  'Weaviate',
  'ChromaDB',
  'JavaScript',
  'TypeScript',
  'Node.js',
  'Express.js',
  'React',
  'Next.js',
  'Redux',
  'Nuxt.js',
  'HTML',
  'CSS',
  'TailwindCSS',
  'SASS',
  'Git',
  'Docker',
  'Heroku',
  'AWS'
]

const contact = {
  email: 'devcutup@gmail.com',
}

export { header, about, projects, skills, contact }
